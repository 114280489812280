
import axios from 'axios'
export default {
    namespaced: true,
    state: {
        products: []
    },
    getters: {
        productsActives(state){
            // return state.products.filter((product) =>  product.status == true && !product.isgame)
            return state.products.filter((product) =>  product.status == true )
        }
    },
    mutations: {
        GET_PRODUCTS(state, payload){
            state.products =  payload
        },
        ADD_PRODUCT(state, payload){
            state.products.push(payload)
        },
    },
    actions: {
        getProducts({commit}, payload) {
            return new Promise((resolve, reject) => {
                return axios.post('/pages/products', payload)
                    .then(response => {
                        commit('GET_PRODUCTS', response.data)
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        fetchProduct({ commit }, payload) {
            return new Promise((resolve, reject) => {
                return axios.get('products/' + payload.id, {params: payload.params})
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch(error => reject(error))
            })
        },
        getCategories({commit}) {
            return new Promise((resolve, reject) => {
                return axios.get('category')
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        addProduct({commit}, productData) {
            return new Promise((resolve, reject) => {
                return axios.post('products', productData)
                    .then(response => {
                        // commit('ADD_PRODUCT', response.data)
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        addCredentialsJson({commit}, productCredentials) {
            return new Promise((resolve, reject) => {
                return axios.post('add-credentials-json', productCredentials)
                .then(response => {
                    resolve(response)
                })
                .catch(error => reject(error))
            })
        },
        deleteProduct({commit}, idProduct) {
            return new Promise((resolve, reject) => {
                return axios.delete('products/' + `${idProduct}`)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        updateProduct({commit}, productData) {
            return new Promise((resolve, reject) => {
                return axios.put('products/' + `${productData._id}`, productData)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
        importGameApi({commit}, {productId, file}) {
            return new Promise((resolve, reject) => {
                return axios.post(`apigames/${productId}`, file, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    resolve(response)
                })
                .catch(error => reject(error))
            })
        },
        launchGame({commit}, payload) {
            return new Promise((resolve, reject) => {
                return axios.post(`/v2/product/${payload.productId}/launch`, payload)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => reject(error))
            })
        },
    },
}
