export default [
  {
    path: "/pages/reports/plays",
    name: "reports-plays",
    component: () => import("@/views/pagesIntegrations/reports/plays"),
    meta: {
      pageTitle: "Transacciones",
      breadcrumb: [
        {
          text: "List",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pages/reports/logs-V2",
    name: "logs-V2",
    component: () => import("@/views/pagesIntegrations/reports/logsV2"),
    meta: {
      pageTitle: "Logs V2",
      breadcrumb: [
        {
          text: "Lista",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pages/reports/log-connections",
    name: "reports-log-connections",
    component: () => import("@/views/pagesIntegrations/reports/logConnections"),
    meta: {
      pageTitle: "Log response Connections",
      breadcrumb: [
        {
          text: "Lista",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pages/reports/log-request",
    name: "reports-log-request",
    component: () => import("@/views/pagesIntegrations/reports/requestLogs"),
    meta: {
      pageTitle: "Log request Connections",
      breadcrumb: [
        {
          text: "Lista",
          active: true,
        },
      ],
    },
  },
  {
    // reports-log-error
    path: "/pages/reports/log-error",
    name: "reports-log-error",
    component: () => import("@/views/pagesIntegrations/reports/errorLogs"),
    meta: {
      pageTitle: "Log Error",
      breadcrumb: [
        {
          text: "Lista",
          active: true,
        },
      ],
    },
  },
  {
    // reports-log-launch
    path: "/pages/reports/log-launch",
    name: "reports-log-launch",
    component: () => import("@/views/pagesIntegrations/reports/launchLogs"),
    meta: {
      pageTitle: "Log Launch",
      breadcrumb: [
        {
          text: "Lista",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pages/reports/log-debug",
    name: "reports-log-debug",
    component: () => import("@/views/pagesIntegrations/reports/debugLogs"),
    meta: {
      pageTitle: "Log Resquest Connections",
      breadcrumb: [
        {
          text: "Lista",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pages/reports/profit-by-users",
    name: "profit-by-users",
    component: () =>
      import("@/views/pagesIntegrations/reports/ProductsByUsers"),
    meta: {
      pageTitle: "Profit by users",
      breadcrumb: [
        {
          text: "list",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pages/reports/most-played",
    name: "most-played",
    component: () =>
      import("@/views/pagesIntegrations/reports/MostPlayed/index"),
    meta: {
      pageTitle: "Most Played",
      breadcrumb: [
        {
          text: "list",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pages/reports/products-by-games",
    name: "products-by-games",
    component: () =>
      import("@/views/pagesIntegrations/reports/ProductsByGames"),
    meta: {
      pageTitle: "Products By Games",
      breadcrumb: [
        {
          text: "list",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pages/reports/games",
    name: "games",
    component: () => import("@/views/pagesIntegrations/reports/Games"),
    meta: {
      pageTitle: "Games",
      breadcrumb: [
        {
          text: "list",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pages/reports/products",
    name: "products",
    component: () => import("@/views/pagesIntegrations/reports/Products"),
    meta: {
      pageTitle: "Productos",
      breadcrumb: [
        {
          text: "list",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pages/reports/products-by-brands",
    name: "products-by-brands",
    component: () =>
      import("@/views/pagesIntegrations/reports/ProductsByBrands"),
    meta: {
      pageTitle: "Productos por marcas",
      breadcrumb: [
        {
          text: "list",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pages/reports/poker",
    name: "reports-poker",
    component: () => import("@/views/pagesIntegrations/reports/poker"),
    meta: {
      pageTitle: "Poker",
      breadcrumb: [
        {
          text: "Report",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pages/reports/poker-detailed-by-user",
    name: "reports-poker-detailed-by-user",
    component: () =>
      import("@/views/pagesIntegrations/reports/pokerDetailedByUser"),
    meta: {
      pageTitle: "Poker Detailed By User",
      breadcrumb: [
        {
          text: "Report",
          active: true,
        },
      ],
    },
  },
  {
    path: "/pages/reports/poker",
    name: "reports-poker",
    component: () => import("@/views/pagesIntegrations/reports/poker"),
    meta: {
      pageTitle: "Poker",
      breadcrumb: [
        {
          text: "Report",
          active: true,
        },
      ],
    },
  },
];
